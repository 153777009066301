import React, { useEffect, useState } from 'react'
import moment from 'moment'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import { connect, useDispatch, useSelector } from 'react-redux'
import { useConfirm } from 'material-ui-confirm'
import DeleteIcon from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'
import PhotoIcon from '@mui/icons-material/Photo';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import HelpIcon from '@mui/icons-material/Help';
import { withStyles } from '@mui/styles'
import TableViewIcon from '@mui/icons-material/TableView';
import Typography from '@mui/material/Typography'
import DescriptionIcon from '@mui/icons-material/Description';
import { toast } from 'react-toastify'
import AlertIcon from '@mui/icons-material/Warning';
import { uploadFile } from '_services'
import {
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Tooltip,
  Skeleton,
  Input
} from '@mui/material'
import {
  ValidatorForm,
  SelectValidator,
  TextValidator
} from 'react-material-ui-form-validator'
import { MANUAIS_CATEGORIAS } from '../../utils'
import {
  empresaAnexoAction,
  tipoAnexoAction
} from '../../_actions'
import { withRouter, } from 'react-router-dom'
import SaveIcon from '@mui/icons-material/Save';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import EditIcon from '@mui/icons-material/Edit'
import { useDebounce } from 'use-debounce';
import { AccessControl } from '../../components/Utils/AccessControl'
import { isEmpty } from 'lodash'
import CustomButton from '../../components/CustomButton/CustomButton'
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const EmpresaAnexoEdit = (props) => {

  const dispatch = useDispatch()
  const confirm = useConfirm()

  const { classes, idEmpresa, ...rest } = props
  const { match: { params } } = props;
  const { totalRegistros } = props.empresaAnexo
  const { isLoading } = props.empresa
  const formRef = React.createRef(null);
  const [key, setKey] = useState(1);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('data');
  const [direction] = useState('desc');

  const [open, setOpen] = useState(false)
  const [file, setFile] = useState(undefined)

  const { empresaAnexo } = useSelector(
    (state) => state.empresaAnexo)

  const { tipoAnexo } = useSelector(
    (state) => state.tipoAnexo)

  useEffect(() => {
    dispatch(empresaAnexoAction.clear())

    dispatch(tipoAnexoAction.getTiposAnexosEmpresaSelect())

  }, []);

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdEmpresa: (idEmpresa && idEmpresa != '' && idEmpresa)
      };

      dispatch(empresaAnexoAction.getEmpresaAnexo(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction, idEmpresa]);

  useEffect(() => {

  }, [totalRegistros, isLoading]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (id) => {
    dispatch(empresaAnexoAction.getEmpresaAnexoById(id))
  }

  const handleClean = () => {
    dispatch(empresaAnexoAction.clearDetail())
    setKey(0);
  }

  const handleDelete = (id) => {
    confirm({
      title: 'Você deseja excluir este anexo?',
      description: 'Essa operação é irreversível',
      disabled: props.empresaAnexo.isLoading,
      confirmationText: props.empresaAnexo.isLoading ? 'Excluindo anexo...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(empresaAnexoAction.deleteEmpresaAnexoById(id))
    })
  }

  const handleChange = (prop) => (event) => {
    dispatch(empresaAnexoAction.onChangeProps(prop, event))
  }

  const handleAprovaAnexo = (n) => {
    confirm({
      title: 'Você deseja aprovar este anexo?',
      description: 'A situação será alterada para "Aprovado"',
      disabled: props.empresaAnexo.isLoading,
      confirmationText: props.empresaAnexo.isLoading ? 'Aprovando anexo...' : 'Sim, aprovar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(empresaAnexoAction.editEmpresaAnexoAprova(n));
    })
  }

  const handleReprovaAnexo = (n) => {
    confirm({
      title: 'Você deseja reprovar esta anexo?',
      description: 'A situação será alterada para "Recusado"',
      disabled: props.empresaAnexo.isLoading,
      confirmationText: props.empresaAnexo.isLoading ? 'Recusando anexo...' : 'Sim, recusar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(empresaAnexoAction.editEmpresaAnexoRecusa(n));
    })
  }

  const handleSubmit = (event) => {

    if (props.empresaAnexo.idTipoAnexo == 0 || props.empresaAnexo.idTipoAnexo == null) {
      toast.error('Informe o Tipo de Anexo!')
    }
    else if (props.empresaAnexo.caminhoAnexo == '' || props.empresaAnexo.caminhoAnexo == null) {
      toast.error('Informe o Arquivo do Anexo!')
    } else {
      let payload = {
        id: props.empresaAnexo.id,
        idEmpresa: parseInt(idEmpresa),
        idTipoAnexo: props.empresaAnexo.idTipoAnexo,
        dataValidade: moment(props.empresaAnexo.dataValidade).toISOString(),
        caminhoAnexo: props.empresaAnexo.caminhoAnexo
      }

      if (props.empresaAnexo.id) {
        dispatch(empresaAnexoAction.editEmpresaAnexoInfo(props.empresaAnexo.id, payload, true))
      } else {
        dispatch(empresaAnexoAction.createEmpresaAnexo(payload))
      }
    }

  };

  const handleOpenFile = (link) => {
    window.open(link);
  }

  const handleChangeFile = (e) => {
    setFile(e.target.files[0])
  }

  useEffect(() => {
    if (file && file != undefined && file != null) {
      setOpen(true)
      upload()
    }
  }, [file])

  const upload = () => {
    uploadFile(file)
      .then((response) => {
        setTimeout(function () {
          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(empresaAnexoAction.onChangeProps('caminhoAnexo', event2))

          setOpen(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Novo Anexo'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Anexo'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (props.empresaAnexo.id) {
      return <EditText />
    }
    return <InsertText />
  }

  return (

    //{isEditable(props.empresa.ultimoEvento) == true ?
    //permitir enviar anexos de NOTAS mesmo depois de já aprovado o orçamento

    <Grid item lg={12} md={12} xl={12} xs={12}>
      <Grid item md={12} xs={12}>
        <ValidatorForm
          className={classes.form}
          ref={formRef}
          id="formAnexo"
          onSubmit={(event) => handleSubmit(event)}
        >
          <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <CardHeader
              subheader="Cadastro de Anexo"
              title={<SegHeader />}
            />
            <Grid item lg={1} md={1} xl={1} xs={12}>
              <IconButton
                aria-label="Ajuda"
                component="a"
                onClick={() =>
                  handleOpenModalHelp()
                }
                size="small"
              >
                <Tooltip title={'Ajuda'}>
                  <Tooltip>
                    <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                  </Tooltip>
                </Tooltip>
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
          <CardContent lg={12} md={12} xl={12} xs={12}>
            <Grid container spacing={2}>
              <Grid container spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                  <Input
                    id="arquivo"
                    className={classes.textField}
                    label="Arquivo *"
                    onChange={e => handleChangeFile(e)}
                    type="file"
                    placeHolder="Escolher arquivo *"
                    formControl
                    key={key}
                    required
                    margin="dense"
                  />
                </Grid>
                <Grid item lg={3} md={3} xl={3} xs={12}>
                  <SelectValidator
                    variant="outlined"
                    id="idTipoAnexo"
                    label="Tipo Anexo *"
                    margin="normal"
                    onChange={handleChange('idTipoAnexo')}
                    value={props.empresaAnexo.idTipoAnexo}
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    displayEmpty
                    labelId="idTipoAnexo"
                  >
                    <MenuItem disabled value="">
                      <em>Tipo Anexo *</em>
                    </MenuItem>
                    {!isEmpty(tipoAnexo) &&
                      undefined !== tipoAnexo &&
                      tipoAnexo.length &&
                      tipoAnexo.map((row) => (
                        <MenuItem key={row.value} value={row.value}>
                          {row.text}
                        </MenuItem>
                      ))}
                  </SelectValidator>
                </Grid>

                <Grid item lg={3} md={3} xl={3} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    type="date"
                    id="dataValidade"
                    label="Validade do Documento"
                    margin="normal"
                    onChange={handleChange('dataValidade')}
                    value={props.empresaAnexo.dataValidade ? moment(props.empresaAnexo.dataValidade).format("yyyy-MM-DD") : ''}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                * Campos obrigatórios
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xl={12} xs={12} style={{
              display: 'flex',
              alignItems: 'right',
              flexDirection: 'row',
              textAlgin: 'right',
              justifyContent: 'right'
            }}>
              <CustomButton
                variant="outlined"
                style={{ color: '#000', borderColor: '#000' }}
                onClick={() => handleClean()}
              >
                Cancelar
              </CustomButton>
              <CustomButton
                type="submit"
                variant="contained"
                style={{ backgroundColor: '#000', color: '#FFF' }}
                disabled={props.empresaAnexo.isLoading || props.empresaAnexo.valorReferencia == '2' || open}
                startIcon={<SaveIcon />}
                endIcon={props.empresaAnexo.isLoading || open && <CircularProgress size={24} />}
              >
                {props.empresaAnexo.id ? (props.empresaAnexo.isLoading ? 'Atualizando Anexo...' : 'Atualizar') : (props.empresaAnexo.isLoading ? 'Salvando Anexo...' : 'Salvar')}
              </CustomButton>
            </Grid>
          </CardActions>
        </ValidatorForm>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xl={3} lg={3} md={3} xs={12}>
          <Input
            id="term"
            label="term"
            placeholder="Filtro"
            value={term}
            fullWidth
            onChange={handleChange('term')}
          />
        </Grid>
      </Grid>
      <br />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell>Tipo de Anexo</TableCell>
              <TableCell>Anexo</TableCell>
              <TableCell align="right">Validade</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {undefined !== empresaAnexo && empresaAnexo.length
              ? empresaAnexo.map((n) => {

                const pathArray = n.caminhoAnexo.split('/');
                const nomeArquivo = pathArray[pathArray.length - 1];

                return (
                  <TableRow hover key={n.id}>
                    <TableCell component="th" scope="row">
                      {n.id ? n.id : ''}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {n.tipoAnexo ? n.tipoAnexo.nome : ''}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginTop: 4 }}>{n.situacao == 1 ? <Tooltip title="Aprovado"><CheckCircleIcon style={{ color: '#4CAF50' }} /></Tooltip> : n.situacao == 2 ? <Tooltip title="Recusado"><CancelIcon style={{ color: '#FF5722' }} /></Tooltip> : <Tooltip title="Pendente de análise"><WatchLaterIcon style={{ color: '#9E9E9E' }} /></Tooltip>}</div>&nbsp;
                        {n.caminhoAnexo ? n.caminhoAnexo.includes('pdf') ?
                          <Tooltip title="Visualizar arquivo em uma nova aba">
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', cursor: 'pointer' }} onClick={() => handleOpenFile(n.caminhoAnexo)}>{nomeArquivo} <span><PictureAsPdfIcon style={{ paddingLeft: 3, marginTop: 4 }} /></span>
                            </div>
                          </Tooltip>
                          : (n.caminhoAnexo.includes('.jpg') || n.caminhoAnexo.includes('.jpeg') || n.caminhoAnexo.includes('.png') || n.caminhoAnexo.includes('.svg') || n.caminhoAnexo.includes('webp')) ?
                            <Tooltip title="Visualizar imagem em uma nova aba"><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', cursor: 'pointer' }} onClick={() => handleOpenFile(n.caminhoAnexo)}>{nomeArquivo} <span><PhotoIcon style={{ paddingLeft: 3, marginTop: 4 }} /></span></div></Tooltip>
                            : n.caminhoAnexo.includes('.txt') ?
                              <Tooltip title="Visualizar arquivo em uma nova aba"><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', cursor: 'pointer' }} onClick={() => handleOpenFile(n.caminhoAnexo)}>{nomeArquivo} <span><DescriptionIcon style={{ paddingLeft: 3, marginTop: 4 }} /></span></div></Tooltip>
                              : (n.caminhoAnexo.includes('.xls') || n.caminhoAnexo.includes('.xlsx') || n.caminhoAnexo.includes('.csv')) ?
                                <Tooltip title="Visualizar arquivo em uma nova aba"><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', cursor: 'pointer' }} onClick={() => handleOpenFile(n.caminhoAnexo)}>{nomeArquivo} <span><TableViewIcon style={{ paddingLeft: 3, marginTop: 4 }} /></span></div></Tooltip>
                                : n.caminhoAnexo : ''}
                      </div>
                    </TableCell>
                    <TableCell align="right" component="th" scope="row">
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
                        {n.dataValidade && new Date(n.dataValidade) < new Date() ? (
                          <Tooltip title="O documento está vencido"><AlertIcon style={{ paddingLeft: 3, color: '#000' }} /></Tooltip>
                        ) : null}
                        <span style={{ marginTop: 3 }}>{n.dataValidade ? moment(n.dataValidade).format("DD/MM/yyyy") : ''}</span>
                      </div>
                    </TableCell>
                    <TableCell align="center">
                      {/*<AccessControl*/}
                      {/*  rule={'empresasAnexos.edit'} //permissão necessária para acessar conteúdo*/}
                      {/*  yes={() => (*/}
                      {/*    <IconButton*/}
                      {/*      aria-label="Edit"*/}
                      {/*      component="a"*/}
                      {/*      onClick={() => handleEdit(n.id)}*/}
                      {/*      size="small"*/}
                      {/*    >*/}
                      {/*      <Tooltip title={'Editar'}>*/}
                      {/*        <Tooltip >*/}
                      {/*          <EditIcon />*/}
                      {/*        </Tooltip>*/}
                      {/*      </Tooltip>*/}
                      {/*    </IconButton>*/}
                      {/*  )}*/}
                      {/*/>*/}
                      <AccessControl
                        rule={'empresasAnexos.delete'} //permissão necessária para acessar conteúdo
                        yes={() => (
                          <IconButton
                            aria-label="Delete"
                            onClick={() => handleDelete(n.id)}
                            size="small"
                          >
                            <Tooltip title="Excluir">
                              <DeleteIcon />
                            </Tooltip>
                          </IconButton>
                        )}
                      />
                      <AccessControl
                        rule={'empresasAnexos.approve'} //permissão necessária para acessar conteúdo
                        yes={() => (
                          <IconButton
                            aria-label="Aprovar"
                            disabled={n.situacao == 1}
                            component="a"
                            onClick={() =>
                              handleAprovaAnexo(n)
                            }
                            size="small"
                          >
                            <Tooltip title={'Aprovar'}>
                              <Tooltip >
                                <DoneIcon />
                              </Tooltip>
                            </Tooltip>
                          </IconButton>
                        )}
                      />
                      <AccessControl
                        rule={'empresasAnexos.refusal'} //permissão necessária para acessar conteúdo
                        yes={() => (
                          <IconButton
                            aria-label="Reprovar"
                            onClick={() => handleReprovaAnexo(n)}
                            size="small"
                            disabled={n.situacao == 2}
                          >
                            <Tooltip title="Reprovar">
                              <CloseIcon />
                            </Tooltip>
                          </IconButton>
                        )}
                      />
                    </TableCell>
                  </TableRow >
                )
              })
              :
              (
                !isLoading ?
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={6} align="center">Nenhum registro encontrado</TableCell>
                  </TableRow>
                  :
                  <TableRow hover>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalRegistros}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeLimit}
      />
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.EMPRESA_EDIT_ANEXO}
          />
          :
          null
        )
      }
    </Grid>

  )
}

EmpresaAnexoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedEmpresaEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(EmpresaAnexoEdit))
)
export { connectedEmpresaEditPage as EmpresaAnexoEdit }
