import { crudService } from '../_services/'
import { history } from '../utils'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'
import { obraEtapaAction, obraAction } from '_actions'

export const obraMedicaoAction = {
  getObraMedicao,
  getObraMedicaoGraficoGantt,
  getObraMedicaoSelect,
  getObraMedicaoById,
  onChangeProps,
  editObraMedicaoInfo,
  editObraMedicaoAprova,
  editObraMedicaoRecusa,
  createObraMedicao,
  deleteObraMedicaoById,
  clear,
  clearDetail,
  notIsLoading,
  isLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getObraMedicao(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasMedicoes' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObraMedicaosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getObraMedicaoGraficoGantt(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasMedicoes/gantt' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObraMedicaosList(response.data))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getObraMedicaoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'obrasMedicoes/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeObraMedicaosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createObraMedicao(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasMedicoes'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Cadastro realizado com sucesso!')
          dispatch(clear())
          dispatch(notIsLoading())
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function request(payload) {
    return { type: 'CREATE_OBRA_MEDICAO_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_OBRA_MEDICAO_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'OBRA_MEDICAO_CLEAR'
  }
}

function editObraMedicaoInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasMedicoes/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
        } else {
          //  toast.error('Oops! Erro ao alterar Obra! 😥')
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao alterar Medicao! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_MEDICAO_FAILURE', error }
  }
}

function editObraMedicaoAprova(id, idObra, status, motivo) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasMedicoes/aprova/' + id
    crudService
      .put(apiEndpoint, { motivo: motivo ? motivo : null })
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(notIsLoading())
          let filterModel = {
            Page: 0,
            Term: '',
            IdObra: idObra,
            Situacao: status
          };

          dispatch(getObraMedicao(filterModel, true))
          dispatch(obraEtapaAction.getObraEtapa(filterModel, true))
          dispatch(obraAction.getGraficoEvolucaoObra(idObra))
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar Obra! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_MEDICAO_FAILURE', error }
  }
}

function editObraMedicaoRecusa(id, idObra, status, motivo) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasMedicoes/recusa/' + id
    crudService
      .put(apiEndpoint, { motivo: motivo ? motivo : null })
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(notIsLoading())
          let filterModel = {
            Page: 0,
            Term: '',
            IdObra: idObra,
            Situacao: status
          };

          dispatch(getObraMedicao(filterModel, true))
          dispatch(obraEtapaAction.getObraEtapa(filterModel, true))
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar Obra! 😥')
      })
  }
}

function request(payload) {
  return { type: 'EDIT_OBRA_MEDICAO_REQUEST', payload }
}

function success(user) {
  return {
    type: 'EDIT_OBRA_MEDICAO_SUCCESS',
    user: user
  }
}

function failure(error) {
  return { type: 'EDIT_OBRA_MEDICAO_FAILURE', error }
}


function getObraMedicaoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'obrasMedicoes/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editObraMedicaosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem' || props == 'obrasMedicoesItens') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteObraMedicaoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasMedicoes/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteObraMedicaosDetails())
      dispatch(notIsLoading())
      history.push('/obrasCronogramas')
      dispatch(getObraMedicao(filterModel))
    })
  }
}
export function changeObraMedicaosList(obraMedicao, totalRows) {
  return {
    type: 'FETCHED_ALL_OBRA_MEDICAO',
    obraMedicao: obraMedicao,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'OBRA_MEDICAO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editObraMedicaosDetails(obraMedicao) {
  return {
    type: 'OBRA_MEDICAO_DETAIL',
    id: obraMedicao.id,
    data: obraMedicao.data,
    idObra: obraMedicao.idObra,
    obra: obraMedicao.obra,
    idUsuario: obraMedicao.idUsuario,
    usuario: obraMedicao.usuario,
    excluido: obraMedicao.excluido,
    titulo: obraMedicao.titulo,
    valor: obraMedicao.valor,
    situacao: obraMedicao.situacao,
    obrasMedicoesEtapasItens: obraMedicao.obrasMedicoesEtapasItens,
    obrasOcorrencias: obraMedicao.obrasOcorrencias
  }
}
export function createUserInfo() {
  return {
    type: 'OBRA_MEDICAO_CREATED_SUCCESSFULLY'
  }
}

export function clearDetail() {
  return {
    type: 'OBRA_MEDICAO_CLEAR_DETAIL'
  }
}

export function deleteObraMedicaosDetails() {
  return {
    type: 'DELETED_OBRA_MEDICAO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'OBRA_MEDICAO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'OBRA_MEDICAO_NOTISLOADING'
  }
}
