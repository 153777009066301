import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Divider,
  Grid,
  List,
  ListItemButton,
  MenuItem,
  Typography
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { Page } from 'components'
import MapPublic from '../components/Maps/MapPublic'
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { estadoAction, obraAction, obraAnexoAction, orgaoAction } from '../_actions'
import { editObrasDetails } from '../_actions/obra.actions'
import logo from "../img/raiar.png";
import CollectionsSharpIcon from '@mui/icons-material/CollectionsSharp';
import { Box } from '@mui/system'
import moment from 'moment/moment'
import CustomButton from '../components/CustomButton'
import CarouselImage from '../components/Carousel/CarouselImage'
import { LoadingButton } from '@mui/lab'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(0),
    margin: '-8px',
    height: '100vh'
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '1px solid #0005',
    paddingBottom: '16px',
    marginBottom: '16px'
  },
  emptyList: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  }
})

const MapaPublico = (props) => {

  const dispatch = useDispatch();

  const { classes } = props;

  const formMapRef = useRef(null);

  const estados = useSelector((state) => state.estado.estado);
  const orgaos = useSelector((state) => state.orgao.orgao);

  const [filtro, setFiltro] = useState({ idEstado: '', idOrgao: '' });
  const [carouselVisible, setCarouselVisible] = useState(false);

  useEffect(() => {
    dispatch(estadoAction.getEstadoSelect());
  }, []);

  useEffect(() => {
    if (filtro.idEstado) {
      dispatch(orgaoAction.getOrgaoSelectPublic(`?Estados=${filtro.idEstado}`));
    }
  }, [filtro.idEstado]);

  useEffect(() => {
    if (filtro.idOrgao) {
      dispatch(obraAction.getObraSelectPublic(`?IdOrgao=${filtro.idOrgao}`));
    }
  }, [filtro.idOrgao]);

  const handleOpenCarousel = () => {
    dispatch(obraAnexoAction.getObraAnexoPublic({ IdObra: props.obra?.id }));
    setCarouselVisible(true);
  }

  const renderListObras = () => {
    return (
      <List>
        {props.obra.obra.map((obra) => {
          if (obra.latitude && obra.longitude) {
            return (
              <Box key={obra.id} mb={3}>
                <ListItemButton
                  selected={obra.id === props.obra.id}
                  onClick={() => dispatch(editObrasDetails(obra))}
                  className={classes.listItem}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    gap="8px"
                    style={{ width: '100%' }}
                  >
                    <img src={obra?.caminhoImagem || logo} width={50} height={50} style={{ borderRadius: '5px', objectFit: obra.caminhoImagem ? 'cover' : 'contain' }} />
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      style={{ width: '100%' }}
                    >
                      <Typography variant="h6" mb={1} style={{ fontSize: '0.9rem', lineHeight: '0.9rem' }}>{obra.titulo}</Typography>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ width: '100%' }}
                      >
                        <Typography variant="p" fontSize="12px">Ínicio: {moment(obra?.dataInicio).format('DD/MM/YYYY')}</Typography>
                        <Typography variant="p" fontSize="12px">{obra && obra.dataTermino ? 'Término:' + moment(obra?.dataTermino).format('DD/MM/YYYY') : ''}</Typography>
                      </Box>
                      {obra?.valorReferenciaComBdi &&
                        <Typography variant="p" mt={1} fontSize="12px" fontWeight="bold" textAlign="right" style={{ width: '100%' }}>
                        Valor de referência: R${obra.valorReferenciaComBdi.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                        </Typography>
                      }
                    </Box>
                  </Box>

                  {props.obra?.id === obra.id &&
                    <CustomButton dark style={{ marginTop: '20px', width: '100%' }} onClick={handleOpenCarousel}>
                      <CollectionsSharpIcon />
                      <Typography variant="p" ml={1} fontSize="12px">Galeria</Typography>
                    </CustomButton>
                  }
                </ListItemButton>
                <Divider />
              </Box>
            );
          }
        }
        )}
      </List>
    )
  }

  const renderEmptyList = () => {
    return (
      <div className={classes.emptyList}>
        <Typography variant="p" align="center">{filtro.idOrgao ? 'Sem obras até o momento' : 'Escolha um órgão público'}</Typography>
      </div>
    );
  }

  return (
    <Page className={classes.root} title="Acompanhar Obras">
      {carouselVisible && <CarouselImage images={props.obraAnexo.obraAnexo} openCarousel={carouselVisible} handleClose={() => setCarouselVisible(false)} />}

      <Grid container direction="row">
        <Grid item lg={8} md={8} xl={8} xs={12} style={{ height: '100vh', position: 'relative' }}>
          <MapPublic obras={filtro.idOrgao ? props?.obra?.obra : []} selectedId={props.obra.id || props.obra.obra[0]?.id} region={filtro.idOrgao ? { lat: props.obra.latitude, lng: props.obra.longitude } : {}} />
        </Grid>
        <Grid item lg={4} md={4} xl={4} xs={12} padding={2} display="grid" gridTemplateRows="auto 1fr" style={{ height: '100vh', maxHeight: '100vh' }}>
          <Grid item gridRow={1}>
            <div className={classes.logo}>
              <img src={logo} width={250} />
            </div>
            <Typography variant="h5" align="center">Acompanhar Obras Públicas</Typography>
            {estados?.length > 0 &&
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <ValidatorForm
                  className={classes.form}
                  ref={formMapRef}
                  id="formMap"
                  onSubmit={(event) => console.log(event)}
                >
                  <Grid container direction="row" spacing={2}>
                    <Grid item lg={3} md={3} xl={3} xs={4}>
                      <SelectValidator
                        className={classes.textField}
                        variant="outlined"
                        id="idEstado"
                        label="Estado"
                        value={filtro.idEstado}
                        onChange={(event) => setFiltro({ ...filtro, idEstado: event.target.value })}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                      >
                        <MenuItem disabled value="">
                          <em>Estado</em>
                        </MenuItem>
                        {estados.map((row, index) => (
                          <MenuItem key={index} value={row.value}>
                            {row.text}
                          </MenuItem>
                        ))}
                      </SelectValidator>
                    </Grid>

                    <Grid item lg={9} md={9} xl={9} xs={8}>
                      <SelectValidator
                        className={classes.textField}
                        variant="outlined"
                        id="idOrgao"
                        label="Órgão"
                        value={filtro.idOrgao}
                        disabled={!filtro.idEstado}
                        onChange={(event) => setFiltro({ ...filtro, idOrgao: event.target.value })}
                        margin="normal"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                      >
                        <MenuItem disabled value="">
                          <em>Órgão público</em>
                        </MenuItem>
                        {props.orgao.isLoading && <LoadingButton loading style={{ height: '150px', width: '100%' }} />}
                        {(!props.orgao.isLoading && orgaos?.length > 0) && orgaos.map((row, index) => (
                          <MenuItem key={index} value={row.value}>
                            {row.text}
                          </MenuItem>
                        ))}
                      </SelectValidator>
                    </Grid>
                  </Grid>
                </ValidatorForm>
              </Grid>
            }
          </Grid>
          <Grid item gridRow={2} overflow="scroll" style={{ overflowX: 'hidden' }}>
            {filtro.idOrgao > 0 ? renderListObras() : renderEmptyList()}
          </Grid>
        </Grid>
      </Grid>
    </Page >
  )
}
MapaPublico.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedMapaPublicoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(MapaPublico))
)
export { connectedMapaPublicoPage as MapaPublico }
